import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { StyleThemeContext } from "../../../contexts/style-theme.context";
import { CommunityContext } from "../../../contexts/community.context";
import { RecentCoinFlipsProvider } from "../../../contexts/recent-coin-flips.context";
import { SocialBar, ToolBar } from "../../components";
import MenuBar from "../../components/menu-bar";
import { TopCoinFlipsProvider } from "../../../contexts/top-coin-flips.context";
import InfoBar from "../../components/info-bar";
import { SiteStatsProvider } from "../../../contexts/stats.context";
import { NavBar } from "../../components/nav-bar";

const BG_IMAGE_PAGES = [
    '/',
    '/premint',
    '/mint'
];

const BG_VIDEO_PAGES = [
    '/',
    "/home"
];

const ClassicLayout = () => {
    const { style } = useContext(StyleThemeContext);
    const { community } = useContext(CommunityContext);
    const location = useLocation();

    const bgImageUrl = isMobile ? community?.backgroundImageMobile ?? community?.backgroundImage : community?.backgroundImage;
    return (
        <div className={style}>
            <div className={"classic-container h-100vh"}
                style={{
                    background: BG_IMAGE_PAGES.includes(location.pathname) ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bgImageUrl})` : ''
                }}>
                <SiteStatsProvider>
                    <RecentCoinFlipsProvider>
                        <TopCoinFlipsProvider>
                            {/* <ToolBar></ToolBar> */}
                            {/* <MenuBar></MenuBar> */}
                            <Outlet></Outlet>
                            <InfoBar></InfoBar>
                            <SocialBar social={community?.social}></SocialBar>
                        </TopCoinFlipsProvider>
                    </RecentCoinFlipsProvider>
                </SiteStatsProvider>
            </div>
        </div>
    );
};
export default ClassicLayout;