import { useState, useEffect } from "react";
import { GalleryArtPieces } from "../../components/gallery/ArtPieces"
import { GalleryForm } from "../../components/gallery/Form"
import { GalleryMenu } from "../../components/gallery/Menu"
import { ImageModal } from "../../components/modals/ImageModal";
import { NavBar } from "../../layout/components/nav-bar";

function range(start: any, end: any) {
    var myArray = [];
    for (var i = start; i <= end; i += 1) {
        myArray.push(i);
    }
    return myArray;
};
function generateRandom(min = 0, max = 100) {

    // find diff
    let difference = max - min;

    // generate random number 
    let rand = Math.random();

    // multiply with difference 
    rand = Math.floor(rand * difference);

    // add with min value 
    rand = rand + min;

    return rand;
}


const ITEMS_PER_PAGE = 32;
const STARTING_NUM = generateRandom(0, 20000);

export const Gallery = () => {
    const [number, setNumber] = useState(STARTING_NUM);
    const [ids, setIds] = useState(range(STARTING_NUM, ITEMS_PER_PAGE + STARTING_NUM));

    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [modalNum, setModalNum] = useState();

    useEffect(() => {
        setIds(
            number === STARTING_NUM ? range(number - 1, ITEMS_PER_PAGE + (number - 1)) : [number - 1]
        );
    }, [number]);

    const refresh = () => {
        const r = generateRandom(0, 20000);
        setIds(
            range(r - 1, ITEMS_PER_PAGE + (r - 1))
        );
    }

    return (
        <>
            <main className="page-wrapper">
                <NavBar />
                <GalleryForm number={number} setNumber={setNumber} refresh={refresh}></GalleryForm>
                {/* <GalleryMenu /> */}
                <GalleryArtPieces ids={ids} setModalNum={(e: any) => { setModalNum(e); handleModalOpen()}}  />
            </main>

            {
                showModal && !!modalNum &&
                <ImageModal
                    show={showModal}
                    id={modalNum}
                    styleCss={'light'}
                    onHide={() => { handleModalClose(); }}
                />
            }
        </>
    )
}