import React, { useEffect } from "react";

const Backdrop = ({ onExpand, activeColor, trackIndex, isPlaying }) => {
  useEffect(() => {
    document.documentElement.style.setProperty("--active-color", activeColor);
  }, [trackIndex, activeColor]);

  return (
    <div onClick={onExpand} className={`d-flex justify-content-end py-2 mb-1 cursor-pointer flex-column ${isPlaying ? "playing" : "idle"}`}>
      <div className={`icon me-3 ${isPlaying ? "playing" : "idle"}`}>
        <span />
        <span />
        <span />
      </div>
    </div>
  )
  // return <div className={`rounded shadow ${isPlaying ? "playing" : "idle"}`} />;
};

export default Backdrop;
