import { useWallet } from "@solana/wallet-adapter-react";
import { isMobile } from "react-device-detect";
import { Logo } from "../../components/Logo";
import { VideoBg } from "../../components/VideoBg";
import { NavBar } from "../../layout/components/nav-bar";

export const Marketing = () => {
    const wallet = useWallet();
    return (
        <div className={!wallet.connected ? "" : ""}>
            <>
                {
                    !isMobile &&
                    <div className="video-wrapper">
                        <div className="overlay"></div>
                        <VideoBg />
                    </div>
                }
                <main className="page-wrapper h-100vh">
                    <NavBar />
                    <section className="position-relative h-100vh z-index-5">
                        <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100vh pt-5">
                            <div className="w-100 align-self-end pt-1 pt-md-4 pb-4 my-auto">
                                <Logo />
                            </div>
                            <div className="w-100 align-self-end text-center">
                                <p className="nav d-block text-light fs-sm text-center pb-2 mb-0">
                                    © All rights reserved by Fat Cats. By&nbsp;
                                    <a className="nav-link d-inline-block p-0 text-light" href="https://twitter.com/degenfatcats" target="_blank" rel="noopener">@degenfatcats</a>.
                                </p>
                            </div>
                        </div>

                    </section>
                </main>
            </>
        </div>
    );
}