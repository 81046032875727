import { SocialLinks } from "../../components/SocialLink";

interface SocialProps {
    social: JSX.Element;
}

const SocialBar = ({ social }: any) => {
    return (
        <div className="social-icons-bottom-right">
            <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
                <div className="mt-3 d-flex flex-column shortcut-row">
                    <SocialLinks social={social}></SocialLinks>
                </div>
            </div>
        </div>
    );
}

export default SocialBar;