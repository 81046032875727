import React, { useState, useEffect, useRef } from "react";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import "./styles.css";
import styles from './nowplaying.scss'
import { EnterModal } from "../components/modals/EnterModal";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer = ({ tracks }) => {

  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  // useEffect(() => {
  //   // call api or anything
  //   if (!loaded) {
  //     setLoaded(true);
  //     setTimeout(() => {
  //       setIsPlaying(true);
  //     }, 5000);
  //     handleModalOpen();
  //   }
  // });


  // State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [expand, setExpand] = useState(false);

  // Destructure for conciseness
  const { title, artist, color, image, audioSrc } = tracks[trackIndex];

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.volume = 0.05;
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="audio-player rounded shadow">
      <div className={"track-info d-flex w-100" + (expand ? "" : "")}>
        <img
          onClick={() => setExpand(!expand)}
          className="artwork transform me-2 cursor-pointer"
          src={isPlaying ? `https://i.imgur.com/C4Q7ePY.gif` : `https://i.imgur.com/tS0hlx4.gif`}
          alt={`track artwork for ${title} by ${artist}`}
        />
        {
          (expand || isPlaying) && 
          <Backdrop
            onExpand={() => setExpand(!expand)}
            trackIndex={trackIndex}
            activeColor={color}
            isPlaying={isPlaying}
          />
        }
        <div className={"flex-row py-1 mt-1 pe-5 ms-1 " + (expand ? "d-flex" : "d-none")}>
          <div className="text-start d-flex justify-content-end flex-column" style={{ minWidth: '86px' }}>
            <h6 className={"title-audio text-truncate text-dark"}>{title.toUpperCase()}</h6>
            <span className={"artist text-truncate"}><small>{artist.toUpperCase()}</small></span>
          </div>
          <AudioControls
            isPlaying={isPlaying}
            onPrevClick={toPrevTrack}
            onNextClick={toNextTrack}
            onPlayPauseClick={setIsPlaying}
          />
        </div>
        {/* <div className={"flex-row py-1 mt-1 pe-5 ms-1"} onClick={() => setExpand(!expand)}>
          <i className={"fas fa-chevron-" + (expand ? "left" : "right")}></i>
        </div> */}
        {/* <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        /> */}
        {/* {
          showModal &&
          <EnterModal
            show={showModal}
            walletId={''}
            styleCss={'light'}
            onHide={() => { setIsPlaying(true); handleModalClose(); }}
          />
        } */}
      </div>
    </div>
  );
};

export default AudioPlayer;
