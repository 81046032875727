import { isMobile } from "react-device-detect";
import { MusicAudioPlayer } from "../../audio-player";

const InfoBar = () => {
    return (
        <div className="social-icons-bottom-left">
            <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
                <div className="list-group w-auto">
                    {
                        !isMobile &&
                        <MusicAudioPlayer />
                    }
                </div>
            </div>
        </div>
    );
}
export default InfoBar;