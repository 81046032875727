import { useEffect, useRef, useState } from "react";

export const VideoBg = () => {
    const video = useRef<HTMLVideoElement>(null);
    const [state, setState] = useState<any>({ loading: true });

    useEffect(() => {
        // Pause and clean up on 
        if (video?.current) {
            video?.current?.addEventListener("loadeddata", () => {
                setState({ loading: false });
            });
        }
    }, [video]);

    return (
        <video
            ref={video}
            id="videoBG"
            poster="https://i.imgur.com/SKGV8ri.png"
            preload="auto"
            playsInline
            autoPlay
            muted
            loop
            style={{
                opacity: state?.loading ? 0 : 1,
                transition: "opacity, 2s ease-in-out"
            }}
        >
            <source src="https://glorious-hammers.s3.us-east-2.amazonaws.com/videos/banner-notext.mp4" type="video/mp4" />
        </video>
    );
}