import imgSrc from "./assets/artwork.jpg";
import imgSrc2 from "./assets/artwork2.jpg";
import imgSrc3 from "./assets/artwork3.jpg";
import cali from "./assets/cali-wataboi.mp3";
import fifty from "./assets/50-tobylane.mp3";
import believer from "./assets/imaginecats-believer.mp3";

import bossa from "./assets/potsu-bossauh.mp3";
import sunny from "./assets/potsu-sunny.mp3";
import forwhat from "./assets/potsu-forwhat.mp3";
import mario from "./assets/mario-lofi.mp3";
import showers from "./assets/ProleteR-AprilShowers.mp3";
import sunset from "./assets/jieun-sunset.mp3";
import wherewecomefrom from "./assets/lecrae-wherewecomefrom.mp3";
import lastonestanding from "./assets/skylar-lastonestanding.mp3";

import rap from "./assets/gucci-rap.mp3";
import icetea from "./assets/ice-tea.mp3";




// All of these artists are at https://pixabay.com/music/search/mood/laid%20back/
export default [
  {
    title: "Cali",
    artist: "Wataboi",
    audioSrc: `https://degen-lullaby.s3.us-east-2.amazonaws.com/cali-wataboi.mp3`,
    image: imgSrc,
    color: "#00aeb0"
  },
  {
    title: "Bossa Uh",
    artist: "Potsu",
    audioSrc: bossa,
    image: imgSrc2,
    color: "#5f9fff"
  },
  {
    title: "LoFi",
    artist: "Mario",
    audioSrc: mario,
    image: imgSrc2,
    color: "#EFC0D5"
  },
  {
    title: "Sunny",
    artist: "Potsu",
    audioSrc: sunny,
    image: imgSrc2,
    color: "#A6D7C8"
  },
  // {
  //   title: "A.S.",
  //   artist: "Proleter",
  //   audioSrc: showers,
  //   image: imgSrc2,
  //   color: "#F69F99"
  // },
  {
    title: "Sunset",
    artist: "Ji-Eun",
    audioSrc: sunset,
    image: imgSrc2,
    color: "#3EA6FF"
  },
  {
    title: "Rap",
    artist: "Gucci",
    audioSrc: rap,
    image: imgSrc2,
    color: "#5f9fff"
  },
  {
    title: "For What",
    artist: "Potsu",
    audioSrc: forwhat,
    image: imgSrc2,
    color: "#8F73BF"
  },
  {
    title: "Where We",
    artist: "Lecrae",
    audioSrc: wherewecomefrom,
    image: imgSrc2,
    color: "#ffb77a"
  },
  {
    title: "Ice Tea",
    artist: "Ice King",
    audioSrc: icetea,
    image: imgSrc2,
    color: "#EFC0D5"
  }
  // {
  //   title: "I Wonder",
  //   artist: "DreamHeaven",
  //   audioSrc: iwonder,
  //   image: imgSrc3,
  //   color: "#5f9fff"
  // }
];
