import { useEffect, useRef, useState } from "react";

export const Logo = () => {
    const image = useRef<HTMLImageElement>(null);
    const [state, setState] = useState<any>({ loading: true });

    useEffect(() => {
        // Pause and clean up on 
        if (image?.current) {
            image?.current?.addEventListener("load", () => {
                setState({ loading: false });
            });
        }
    }, [image]);

    return (
        <img
            className="img-fluid"
            ref={image}
            src="https://i.imgur.com/8yVhoWf.png"
            style={{
                opacity: state?.loading ? 0 : 1,
                transition: "opacity, 1s ease-in-out"
            }}
        >
        </img>
    );
}